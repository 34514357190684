@use 'default.them';
@use 'tailwind-colors';
@use '../../../node_modules/animate.css/animate.min.css';
@use 'ngx-toastr/toastr';
@use '../../../node_modules/angular-calendar/css/angular-calendar.css';
@use '../../../node_modules/tippy.js/dist/tippy.css';
@use '../../../node_modules/swiper/swiper-bundle.css';
@use '../../../node_modules/sidebarjs/lib/sidebarjs.css';
@use 'card';
@use 'slide-toggle';
@use 'chart-tooltip';
@use 'beauty-scroll';

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}
body {
  @apply text-txt;
  margin: 0;
  font-family: 'Nunito Sans';
}

.mg-text-caveat-brush {
  font-family: 'Caveat Brush', cursive;
}

// SidebarJs style
[sidebarjs-container] {
  width: 80%;
  max-width: none;
  box-shadow: none !important;
}

// Material Dialog Styles
.normal-modal {
  max-width: calc(100vw - 40px) !important;
  .mat-mdc-dialog-container {
    padding: 0 !important;
    max-height: 90vh !important;

    .mat-mdc-dialog-surface {
      @extend .beautyScroll;
      @apply border border-ui-separator bg-transparent overflow-x-hidden rounded-sm shadow-e1 #{!important};
    }
  }
}

.fullscreen-modal .mat-mdc-dialog-surface {
  border-radius: 0 !important;
}

.heat-map-modal .mat-mdc-dialog-container {
  padding: 0 !important;
  border-radius: 8px !important;
  max-height: 95vh !important;
  width: 80vw !important;
}

.mg-menu-backdrop {
  @apply bg-txt/40;
}

.mg-disable-backdrop-click {
  .mg-menu-backdrop {
    @apply pointer-events-none;
  }
}

.mg-filter-menu {
  @apply mt-sm mb-lg max-h-[90vh] flex flex-col;

  .mat-mdc-menu-content {
    @extend .beautyScroll;
    @apply bg-contrast py-0 flex flex-col overflow-auto;
  }
}

.mat-mdc-menu-panel {
  max-width: none !important;
  border-radius: 4px !important;
}

* {
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
  print-color-adjust: exact !important; /*Firefox*/
}

// Loading Animation
.mg-loading-item {
  @apply bg-white/50 dark:bg-black/50;
  // animation: 1.5s shine linear infinite;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

// Input Autofill remove background
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.trading-chart-tooltip {
  @apply overflow-visible min-w-[200px] bg-white text-black rounded-sm border border-gray-200 shadow-lg absolute hidden p-sm box-border text-sm text-left z-[1000] top-base left-base pointer-events-none;
}

.mg-chartjs-tooltip {
  @apply absolute z-50 w-max overflow-visible bg-white rounded-sm text-base leading-base text-black opacity-100 p-sm shadow pointer-events-none;
  transform: translate(-105%, -50%);
  transition: all 0.1s ease;
}
