.mg-tooltip-comp-sales {
  @apply bg-contrast shadow-e1 rounded-sm opacity-100 pointer-events-none;
  min-width: 340px;
  padding: 0 !important;

  .tooltip-arrow {
    display: none;
  }

  .mg-tooltip-comp-sales-content {
    @apply py-2xl px-lg;
    tr {
      @apply border-b border-gray-200;
    }

    td {
      @apply py-base text-base leading-base first:text-left text-right;
      &:nth-of-type(2) {
        @apply font-semibold;
      }
    }
  }
}
